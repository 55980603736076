import './scss/app.sass';


window.$ = require('jquery');
import Navigo from 'navigo';

import stateManager from './stateManager'
import questions from './questions';
import terms from './terms';
import results from './results';
import pdf from './pdf';
import contact from './contact';

window.addEventListener("load", () => {
    const router = new Navigo("/");
    const pagesDir = '/pages/';

    let staticRoutes = [
        'template',
        'terms',
        'privacy',
        'contact',
    ];

    stateManager.init(router, staticRoutes);

    let lastPage = '';
    let render = function (page, script = false, params = {}) {
        fetch(pagesDir + page + '.html')
            .then(response => response.text())
            .then(text => {
                let isNewApp = false;
                if(lastPage !== page) {
                    $('#content').html(text);
                    isNewApp = true;
                }
                if (script) {
                    script(params.data, isNewApp);
                }

                lastPage = page;

            });
    };

    router
        .on("/contact/:uuid", function (params) {
            render('contact', contact, params);
        })
        .on("/terms", function () {
            render('terms', terms);
        })
        .on("/privacy", function () {
            render('privacy', terms);
        })
        .on("/question/:section/:question", function (params) {
            render('question', questions, params);
        })
        .on("/results", function () {
            render('results', results);
        })
        .on("/template/pdf-results/:uuid", function (params) {
            render('pdf', pdf, params);
        })
        .notFound(() => {
            render('404');
        })
        .resolve();
});



