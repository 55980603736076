const {structure} = require('./structure')
const _ = require('lodash');
const utils = require('./utils');

/////
// router manager between states
/////
let router;
let staticRoutes;


module.exports = {
    init: function (r, s) {

        router = r;
        staticRoutes = s;

        const referer = window.location.search.substring(1);
        utils.setToLS('referer', referer);

        if(!staticRoutes.includes((r.getCurrentLocation().url.split('/')[0]))) {

            let currentState = utils.getCurrentState();

            //case: no state data, init test - make sure we are at the beginning
            if (currentState === null) {
                currentState = structure[0];
                utils.setCurrentState(currentState);
                //redirect if not at the beginning
                if (!utils.isStateEqualToCurrent()) router.navigate(currentState);
            }
            //case: test is finished - not allowed to open questions; redirect
            else if (currentState === _.last(structure)) {
                if (!utils.isStateEqualToCurrent()) {
                    this.resetState();
                }
            }
            //case: test is in progress - make sure we are at the last unanswered question
            else {
                if (!utils.isStateEqualToCurrent()) router.navigate(currentState);
            }
        }
    },
    goToNextState: function(){
        //console.log('going to next state');
        let currentState = utils.getCurrentState();
        const currentIndex = _.indexOf(structure, currentState);
        currentState = structure[currentIndex+1];
        utils.setCurrentState(currentState);
        router.navigate(currentState);
    },
    resetState: function (){
        utils.clearLS();
        this.init(router, staticRoutes);
    }
};